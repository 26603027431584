<template>
  <base-layout  page-title="Profile" showBackButton="true" :scrollCallback="onScroll" contentID="_userProfilePage" :enableHeaderShrink="true">
    <!-- heading and settings -->
    <ion-grid class="grid_layout_nopadding animate-fading">
      <!-- head profile -->
      <profile-head
        :user="user"
        :currentUser="currentUser"
        :userConnections="userConnections"
      >
      </profile-head>
      <!-- tabs for user -->
      <ion-row class="animate-fading">
        <ion-col :size="getColSize(false)" :offset="getOffsetSize(false)">
          <ion-segment
            @ionChange="segmentChanged($event)"
            value="MyProfile"
            v-model="currentTabSegment"
          >
            <ion-segment-button
              v-for="tab in tabs"
              v-bind:key="tab.value"
              :value="tab.value"
              layout="icon-end"
              class="overflow-tab"
            >
              <ion-label v-if="isMobileScreen()" class="ion-text-wrap">{{
                getStrings(strings, tab.name)
              }}</ion-label>
              <ion-label v-if="!isMobileScreen()" class="ion-text-wrap">{{
                getStrings(strings, tab.name)
              }}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-col>
      </ion-row>

      <!-- tab content: Relations/Connection -->
      <ion-row
        class="ion-vertical-padding ion-no-margin"
        v-if="currentTabSegment === 'Relations'"
      >
        <ion-col
          :size="getColSize(false)"
          :offset="getOffsetSize(false)"
          ion-no-margin
          ion-no-padding
        >
          <friend-list
            :tokenizedUser="user.user_id"
            :isSelfUser="currentUser.tokenized_user === user.user_id"
          >
          </friend-list>
        </ion-col>
      </ion-row>

      <!-- tab content: Groups -->
      <ion-row
        class="ion-vertical-padding ion-no-margin animate-fading"
        v-if="currentTabSegment === 'Group'"
      >
        <ion-col
          :size="getColSize(false)"
          :offset="getOffsetSize(false)"
          ion-no-margin
          ion-no-padding
        >
          <!-- show group invites and join request -->

          <ion-spinner v-if="fetchingGroupList" name="dots"></ion-spinner>
          <group-list
            :groupListName="getStrings(strings, 'Member') + ' ' + getStrings(strings, 'Groups')"
            :groups="memberGroupList"
          ></group-list>
          <ion-label
            v-if="memberGroupList.length === 0"
            class="left-margin-15px">
            {{ getStrings(strings, "NotMemberOfAnyGroup") }}.</ion-label
          >
        </ion-col>
      </ion-row>

      <!-- tab content: About / MyProfile -->
      <ion-row v-if="currentTabSegment === 'MyProfile'">
        <ion-col
          :size="getColSize(false)"
          :offset="getOffsetSize(false)"
          class="col_layout_nopadding"
        >
          <ion-spinner v-if="fetchingProfile" name="dots"></ion-spinner>
          <ion-item class="ion-no-padding ion-no-margin" no-lines lines="none">
            <ion-text class="left-margin-15px">
              <br/>
            </ion-text>
          </ion-item>
          <ion-item class="ion-no-padding ion-no-margin" no-lines lines="none" v-if="user.short_profile['user_city']">
            <ion-text class="left-margin-15px line_hight_two">
              <b >Lives in</b> {{ user.short_profile['user_city'] }}
            </ion-text>
          </ion-item>
          <ion-item 
            class="ion-no-padding ion-no-margin"
            no-lines lines="none"
            v-for="prof in user.short_profile['profession']"
            v-bind:key="prof.workplace"
          >
            <ion-text class="left-margin-15px line_hight_two">
              <b >Worked at</b> {{ prof.workplace }}, {{ formatDateYYYY(prof.year) }}
            </ion-text>
          </ion-item>
          <ion-item
            class="ion-no-padding ion-no-margin"
            no-lines lines="none"
            v-for="edu in user.short_profile['education']"
            v-bind:key="edu.institute"
          >
            <ion-text class="left-margin-15px line_hight_two">
              <b >Studied at </b> {{ edu.institute }}, {{ formatDateYYYY(edu.year) }}
            </ion-text>
          </ion-item>
          <ion-item class="ion-no-padding ion-no-margin" no-lines lines="none" v-if="user.short_profile['relationship_status']">
            <ion-text class="left-margin-15px line_hight_two">
              <b >Relationship status </b> {{ user.short_profile['relationship_status'] }}
            </ion-text>
          </ion-item>
          <ion-item class="ion-no-padding ion-no-margin" no-lines lines="none" v-if="user.short_profile['birth_date']">
            <ion-text class="left-margin-15px line_hight_two">
              <b class="line_hight_one">Birth Date </b> {{ formatDateDDMM(user.short_profile['birth_date']) }}
            </ion-text>
          </ion-item>
          <ion-item class="ion-no-padding ion-no-margin" no-lines lines="none" v-if="user.short_profile['blood_group']">
            <ion-text class="left-margin-15px line_hight_two">
              <b class="line_hight_one">Blood Group </b> {{ user.short_profile['blood_group'] }}
            </ion-text>
          </ion-item>
          <span v-if="user.pages.length">
            <ion-item class="ion-no-padding ion-no-margin" no-lines lines="none">
              <ion-text class="left-margin-15px line_hight_two">
                <b class="line_hight_one">Pages </b>
              </ion-text>
            </ion-item>
              <ion-item 
                v-for="page in user.pages"
                :key="page.id"
                :value="page.id"
                no-lines lines="none"
              >
              <ion-button
                fill="clear"
                color="primary"
                style="margin-left: 25px; text-transform: none;"
                @click="navigatePage(page.tokenized_group)"
              >
                {{ page.group_name }} >>
              </ion-button>
            </ion-item>
          </span>
          <br />
        </ion-col>
      </ion-row>

      <!-- tab content: Posts -->
      <ion-row
        class="
          ion-vertical-padding
          profile-post-padding
          ion-no-margin
          animate-fading
        "
        v-if="currentTabSegment === 'Posts'"
      >
        <ion-col
          :size="getColSize(false)"
          :offset="getOffsetSize(false)"
          class="ion-vertical-padding profile-post-padding ion-no-margin"
        >
          <ion-button
            fill="clear"
            size="small"
            v-if="currentUser.tokenized_user === user.user_id"
            @click="togglePostsList"
          >
            <small v-if="!scrollState.isFavourite"
              >&nbsp; {{ getStrings(strings, "ShowOnlyFavourites") }}</small
            >
            <small v-if="scrollState.isFavourite">&nbsp; {{ getStrings(strings, "ShowAllPosts") }}</small>
            <ion-icon
              size="small"
              slot="icon-only"
              color="medium"
              :icon="icon.starOutline"
              v-if="!scrollState.isFavourite"
            ></ion-icon>
            <ion-icon
              size="small"
              slot="icon-only"
              color="warning"
              :icon="icon.star"
              v-if="scrollState.isFavourite"
            ></ion-icon>
          </ion-button>

          <ion-item
            v-if="inPostRefreshMode && posts.length === 0"
            class="animate-fading"
            no-lines lines="none"
          >
            <ion-thumbnail slot="start">
              <ion-skeleton-text></ion-skeleton-text>
            </ion-thumbnail>
            <ion-label>
              <h3>
                <ion-skeleton-text
                  animated
                  style="width: 80%"
                ></ion-skeleton-text>
              </h3>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 60%"
                ></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 30%"
                ></ion-skeleton-text>
              </p>
            </ion-label>
            <br /><br />
          </ion-item>

          <post-list
            :posts="posts"
            :fetchPostAPI="listPosts"
            :scrollState="scrollState"
          ></post-list>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-fab
      slot="fixed"
      vertical="bottom"
      horizontal="end"
      v-if="enableScrollToTop"
    >
      <ion-fab-button
        size="small"
        @click="scrollUpPosts()"
        translucent="true"
        class="animate-fading-1"
      >
        <ion-icon :icon="icon.arrowUpCircleOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab> 
  </base-layout>
</template>

<script>
import {
  IonButton,
  IonIcon,
  IonLabel,
  IonGrid,
  IonCol,
  IonRow,
  IonItem,
  IonText,
  IonSpinner,
  IonSegment,
  IonSegmentButton,
  IonSkeletonText,
  IonThumbnail,
  IonFab,
  IonFabButton,
  alertController,
  popoverController,
} from "@ionic/vue";

import {
  homeOutline,
  personCircleOutline,
  starOutline,
  star,
  arrowBackOutline,
  ellipsisHorizontal,
  personCircle,
  arrowUpCircleOutline
} from "ionicons/icons";

import { useRouter } from "vue-router";

import {
  getAllConnection,
  userProfile,
  listPosts,
  listGroups,
} from "../services/apiCall";

import PostList from "../components/posts/PostList.vue";

import { readFromDB } from "../services/db";
import {
  formatDateYYYY,
  formatDateDDMM,
  formatShortDate,
  isMobileScreen,
  getColSize,
  getOffsetSize,
  defaultPostsPerFetch,
  processPreview,
  logOffApp,
} from "../services/utils";

import { getStrings } from "../services/lang";
import ProfileHead from "../components/profile/ProfileHead.vue";
import FriendList from "../components/profile/FriendList.vue";
import GroupList from "../components/groups/GroupList.vue";
import ProfilePopoverOptions from "../components/profile/ProfilePopoverOptions.vue";

export default {
  components: {
    IonButton,
    IonLabel,
    IonIcon,
    IonGrid,
    IonCol,
    IonRow,
    IonItem,
    IonText,
    IonSpinner,
    IonSegment,
    IonSegmentButton,
    IonSkeletonText,
    IonThumbnail,
    IonFab,
    IonFabButton,
    PostList,
    ProfileHead,
    FriendList,
    GroupList,
  },
  ionViewDidEnter() {
    this.userProfilePageLeft = false;
  },
  ionViewDidLeave() {
    this.userProfilePageLeft = true;
  },
  ionViewWillEnter() {
    this.userProfilePageLeft = false;
    this.init();
  },
  ionViewWillLeave() {
    this.userProfilePageLeft = true;
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    currentUser() {
      return this.$store.getters.user;
    },
    posts() {
      console.log('posts/', this.user.user_id);
      return this.$store.getters.posts.filter(
        (p) => p.post_origin_tag === "User" + this.user.user_id
      );
    },
  },
  data() {
    return {
      popoverController,
      currentTabSegment: "MyProfile",
      friendList: [],
      memberGroupList: [],
      inviteGroupList: [],
      joinGroupRequestList: [],
      fetchingProfile: false,
      fetchingGroupList: false,
      inPostRefreshMode: false,
      userProfilePageLeft: false,
      userConnections: [],
      user: {
        user_id: this.$route.params.profileid,
        full_name: "...",
        photo: "",
        my_bio: "...",
        profile: [],
        short_profile: {},
        joining_date: "",
        pages: [],
      },
      scrollState: {
        scrollDisabled: true,
        scrollSkip: 0,
        isFavourite: false,
        topic_filter: [],
        user_id: "",
        post_origin_tag: "",
      },
      tabs: [
        {
          name: "About",
          value: "MyProfile",
        },
        {
          name: "Contacts",
          value: "Relations",
        },
        {
          name: "Groups",
          value: "Group",
        },
        {
          name: "Posts",
          value: "Posts",
        },
      ],
      icon: {
        homeOutline,
        personCircleOutline,
        starOutline,
        star,
        arrowBackOutline,
        ellipsisHorizontal,
        personCircle,
        arrowUpCircleOutline,
      },
      enableScrollToTop: false,
    };
  },
  setup() {
    const router = useRouter();

    return {
      getColSize,
      getOffsetSize,
      router,
      alertController,
      listPosts,
      processPreview,
      listGroups,
      formatDateYYYY,
      formatDateDDMM,
      formatShortDate,
      isMobileScreen,
      getStrings,
    };
  },
  watch: {
    "$route.params.profileid": function (id) {
      console.log("changed user", id);
      if (!this.userProfilePageLeft) {
        try {
          this.init();
        } catch (err) {
          alert("Error in fetching profile. " + err);
        }
      }
    },
  },
  methods: {
    init() {
      this.user.user_id = this.$route.params.profileid;
      this.currentTabSegment = "MyProfile";
      this.fetchProfile();
      this.getUserConnections();
      this.readDB();
    },
    getUserConnections() {
      if (typeof this.user.user_id === "undefined") {
        this.user.user_id = this.currentUser.tokenized_user;
      }
      getAllConnection({ connection_user_id: this.user.user_id })
        .then((res) => {
          if (res.data.status === 0) {
            this.userConnections = res.data.result.connection_list;
          } else {
            console.log("getUserConnections - err1");
          }
        })
        .catch((err) => {
          console.log("getUserConnections - err2", err);
        });
    },
    readDB(callbackObject = this) {
      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            // read from DB done
            if (
              typeof callbackObject.currentUser === "undefined" ||
              typeof callbackObject.currentUser.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
          });
      } catch (err) {
        console.log("indexdb not available", err);
      }
    },
    async logOff(callbackObject = this) {
      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },
    listThePosts() {
      this.inPostRefreshMode = true;
      listPosts({
        filtered_by: "Me",
        user_id: this.user.user_id,
        limit: defaultPostsPerFetch(),
        skip: 0,
        is_favourite: this.scrollState.isFavourite,
      })
        .then(async (res) => {
          this.scrollState.scrollDisabled = false;
          this.scrollState.scrollSkip = 0;
          this.scrollState.user_id = this.user.user_id;
          this.scrollState.post_origin_tag = "User" + this.user.user_id;

          if (res.data.status === 0) {
            var processedPosts = res.data.result.post_list.map((onePost) => {
              var previewData = processPreview(onePost);
              onePost["previewData"] = previewData;
              onePost["post_origin_tag"] = "User" + this.user.user_id;
              return onePost;
            });

            console.log('listThePosts/profile', processedPosts);

            if (!this.userProfilePageLeft) {
              this.$store.dispatch("updateposts", processedPosts);
              this.inPostRefreshMode = false;
            }
          }
        })
        .catch((err) => {
          console.log("listPost - err", err);
          this.inPostRefreshMode = false;
        });
    },
    fetchProfile() {
      this.fetchingProfile = true;
      if (typeof this.user.user_id === "undefined") {
        this.user.user_id = this.currentUser.tokenized_user;
      }
      this.user.full_name = "...";
      this.user.photo = "";
      this.user.my_bio = "...";
      this.user.short_profile = {};
      this.user.friendCount = 0;
      this.user.followerCount = 0;
      this.user.familyCount = 0;
      this.user.joining_date = "";
      this.user.pages = [];

      userProfile({ profile_user_id: this.user.user_id })
        .then((res) => {
          this.user.short_profile = {};
          if (res.data.status === 0) {
            // basic profile
            this.user.id = res.data.result.id;
            this.user.full_name = res.data.result.full_name;
            this.user.friendCount = res.data.result.friend_count;
            this.user.followerCount = res.data.result.follower_count;
            this.user.familyCount = res.data.result.family_member_count;
            this.user.joining_date = res.data.result.joining_date;
            this.user.are_friends = res.data.result.are_friends;
            this.user.are_family_members = res.data.result.are_family_members;
            this.user.short_profile = res.data.result.short_profile;
            this.user.full_name = res.data.result.full_name;
            this.user.photo = res.data.result.user_profile_photo;
            this.user.profile_photo_available = res.data.result.profile_photo_available;
            this.user.pages = res.data.result.pages;
          }
          this.fetchingProfile = false;
        })
        .catch((err) => {
          console.log("userProfile - err", err);

          this.fetchingProfile = false;
        });
    },
    togglePostsList() {
      this.scrollState.isFavourite = !this.scrollState.isFavourite;
      this.listThePosts();
    },
    segmentChanged(ev) {
      console.log('EV:', ev.detail.value);
      this.fetchingGroupList = true;
      if (this.currentTabSegment === "Group") {
        // get member group
        listGroups({ list_type: "member", tokenized_user: this.user.user_id })
          .then((res) => {
            if (res.data.status === 0) {
              this.memberGroupList = res.data.result.group_list;
              this.inviteGroupList = res.data.result.invite_group_list;
              this.joinGroupRequestList =
                res.data.result.join_group_request_list;
              this.fetchingGroupList = false;
            }
          })
          .catch((err) => {
            console.log("listGroups (member) - err", err);
            this.fetchingGroupList = false;
          });
      } else if (this.currentTabSegment === "Posts") {
        this.listThePosts();
      }
    },
    async openProfileOptionPopover(ev) {
      const popover = await popoverController.create({
        component: ProfilePopoverOptions,
        event: ev,
        translucent: true,
        cssClass: "userlist_popover",
      });

      var options = [
        { id: "CHANGE_PASSWORD", name: this.getStrings(this.strings, "ChangePassword") },
        { id: "EDIT", name: this.getStrings(this.strings, "EditProfile") },
      ];
      if (this.currentUser.tokenized_user !== this.user.user_id) {
        options = [];
        if (this.userConnections.i_am_following) {
          options.push({ id: "UNFOLLOW", name: this.getStrings(this.strings, "UnFollow") });
        } else {
          options.push({ id: "FOLLOW", name: this.getStrings(this.strings, "Follow") });
        }
        if (
          !this.userConnections.are_friends &&
          !this.userConnections.friend_request_received &&
          !this.userConnections.friend_request_sent
        ) {
          options.push({ id: "FRIEND", name: this.getStrings(this.strings, "Friend") });
        } else if (this.userConnections.are_friends) {
          options.push({ id: "UNFRIEND", name: this.getStrings(this.strings, "UnFriend") });
        }
        if (
          !this.userConnections.are_family_members &&
          !this.userConnections.family_request_received &&
          !this.userConnections.family_request_sent
        ) {
          options.push({ id: "FAMILY", name: this.getStrings(this.strings, "Family") });
        } else if (this.userConnections.are_family_members) {
          options.push({ id: "UNFAMILY", name: this.getStrings(this.strings, "UnFamily") });
        }
      }
      popover.componentProps = {
        popoverActions: options,
        parent: popover,
        userConnections: this.userConnections,
        user: this.user,
      };
      return popover.present();
    },
    async navigateMenu() {
      await this.router.push("/menu");
    },
    async navigatePage(tokenizedGroup) {
      await this.router.push("/page/" + tokenizedGroup);
    },
    scrollUpPosts() {
      let el = document.getElementById("_userProfilePage");
      el.scrollToTop(300);
    },
    onScroll(ev) {
      // console.log('onScroll', ev);
      if (ev.detail.scrollTop > 250) {
        this.enableScrollToTop = true;
      } else {
        this.enableScrollToTop = false;
      }
    },
  },
};
</script>