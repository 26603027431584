<template>
  <ion-grid class="ion-no-margin ion-no-padding">
    <ion-row>
      <ion-col>
        <ion-item no-lines lines="none">
          <ion-spinner v-if="fetchingFriendList" name="dots"></ion-spinner>

          <ion-button
            fill="clear"
            v-if="friendRequestList.length > 0 && isSelfUser"
            @click="showFriendRequestListClickHandler"
          >
            {{ getStrings(strings, "FriendRequests") }} ({{ friendRequestList.length }})
            <ion-icon
              v-if="!showFriendRequestList"
              :icon="caretForwardOutline"
              slot="end"
            ></ion-icon>
            <ion-icon
              v-if="showFriendRequestList"
              :icon="caretDownOutline"
              slot="end"
            ></ion-icon>
          </ion-button>
        </ion-item>
        <ion-item v-if="showFriendRequestList" no-lines lines="none">
          <friends-list :friends="friendRequestList" type="full"></friends-list>
        </ion-item>
        <ion-item no-lines lines="none">
          <ion-button
            fill="clear"
            v-if="familyRequestList.length > 0 && isSelfUser"
            @click="showFamilyRequestListClickHandler"
          >
            {{ getStrings(strings, "FamilyRequests") }} ({{ familyRequestList.length }})
            <ion-icon
              v-if="!showFamilyRequestList"
              :icon="caretForwardOutline"
              slot="end"
            ></ion-icon>
            <ion-icon
              v-if="showFamilyRequestList"
              :icon="caretDownOutline"
              slot="end"
            ></ion-icon>
          </ion-button>
        </ion-item>
        <ion-item v-if="showFamilyRequestList" no-lines lines="none">
          <friends-list :friends="familyRequestList" type="full"></friends-list>
        </ion-item>
        <ion-item no-lines lines="none">
          <ion-button
            style="text-transform: none"
            fill="clear"
            @click="showUserList('Friend')"
          >
            <ion-text color="medium" v-if="listType === 'Friend'">
              {{ getStrings(strings, "Friends") }} ({{ friendCount }})
            </ion-text>
            <ion-text color="primary" v-if="listType !== 'Friend'">
              {{ getStrings(strings, "Friends") }} ({{ friendCount }})
            </ion-text>
          </ion-button>
          <ion-button
            color="listType==='Mutual Friends'? 'dark' : 'primary'"
            fill="clear"
            v-if="mutualFriends.length"
            style="text-transform: none"
            @click="showUserList('Mutual Friends')"
          >
            <ion-text color="medium" v-if="listType === 'Mutual Friends'">
              {{ getStrings(strings, "MutualFriends") }} ({{ mutualFriends.length }})
            </ion-text>
            <ion-text color="primary" v-if="listType !== 'Mutual Friends'">
              {{ getStrings(strings, "MutualFriends") }} ({{ mutualFriends.length }})
            </ion-text>
          </ion-button>
          <ion-button
            color="listType==='Family'? 'dark' : 'primary'"
            fill="clear"
            v-if="myFamilyList.length"
            style="text-transform: none"
            @click="showUserList('Family')"
          >
            <ion-text color="medium" v-if="listType === 'Family'">
              {{ getStrings(strings, "Family") }} ({{ myFamilyList.length }})
            </ion-text>
            <ion-text color="primary" v-if="listType !== 'Family'">
              {{ getStrings(strings, "Family") }} ({{ myFamilyList.length }})
            </ion-text>
          </ion-button>
           <ion-button
            color="listType==='Mutual Family'? 'dark' : 'primary'"
            fill="clear"
            v-if="mutualFamily.length"
            style="text-transform: none"
            @click="showUserList('Mutual Family')"
          >
            <ion-text color="medium" v-if="listType === 'Mutual Family'">
              {{ getStrings(strings, "Family") }} ({{ mutualFamily.length }})
            </ion-text>
            <ion-text color="primary" v-if="listType !== 'Mutual Family'">
              {{ getStrings(strings, "Family") }} ({{ mutualFamily.length }})
            </ion-text>
          </ion-button>
          <ion-button
            color="listType==='blocked'? 'dark' : 'primary'"
            fill="clear"
            v-if="blockedUsers.length"
            style="text-transform: none"
            @click="showUserList('blocked')"
          >
            <ion-text color="medium" v-if="listType === 'blocked'">
              {{ getStrings(strings, "Blocked") }} ({{ blockedUsers.length }})
            </ion-text>
            <ion-text color="primary" v-if="listType !== 'blocked'">
              {{ getStrings(strings, "Blocked") }} ({{ blockedUsers.length }})
            </ion-text>
          </ion-button>
        </ion-item>
      </ion-col>
    </ion-row>
    <span v-if="listType!=='blocked'">
      <ion-row class="ion-no-padding ion-no-margin">
        <ion-col
          v-for="ff in userFFList"
          :key="ff"
          :ff="ff"
          size="4"
          class="ion-no-padding ion-no-margin"
        >
          <ion-card class="all-margin-2px">
            <ion-row class="center_image_col">
              <ion-col class="center_image_col">
                <ion-avatar
                  size="large"
                  style="min-width: 50px; min-height: 50px; cursor: pointer"
                  @click="navigateProfile(ff.tokenizedUser)"
                  class="top-margin-10px bottom-margin-10px"
                >
                  <ion-img
                    v-if="!ff.profile_photo_available"
                    size="large"
                    src="assets/icon/misc/avatar.svg"
                  ></ion-img>
                  <ion-img
                    @ionError="ff.profile_photo_available=false"
                    v-if="ff.profile_photo_available"
                    size="large"
                    :src="ff.photo_thumbnail"
                  ></ion-img>
                </ion-avatar>
              </ion-col>
            </ion-row>
            <ion-row class="center_align_col bottom-margin-10px">
              <ion-col>
                <div class="profile_name_div">{{ ff.full_name }}</div>
              </ion-col>
            </ion-row>
          </ion-card>
        </ion-col>
      </ion-row>
      <!-- <ion-infinite-scroll
        @ionInfinite="loadMoreFriends($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="scrollState.scrollDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Loading more Friends..."
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll> -->
    </span>
    <span v-if="listType==='blocked' && isSelfUser">
    <ion-row class="ion-no-padding ion-no-margin">
      <ion-col
        
        class="ion-no-padding ion-no-margin"
      >
        <ion-item
          v-for="ff in userFFList"
          :key="ff"
          :ff="ff"
        >
          <ion-label class="left-margin-15px">{{ ff.full_name }}</ion-label>
          <ion-button
            class="right-margin-15px"
            @click="unblockUser(ff)"
            style="text-transform: none;"
            :disabled="unblockUserClicked"
          >
          Un-block
          </ion-button>
        </ion-item>
      </ion-col>
    </ion-row>
    </span>
  </ion-grid>
</template>

<script>
import {
  IonItem,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonAvatar,
  IonIcon,
  IonImg,
  IonButton,
  IonText,
  IonSpinner,
  IonLabel,
  toastController,
  //IonInfiniteScroll,
  //IonInfiniteScrollContent,
} from "@ionic/vue";

import { useRouter } from "vue-router";

import {
  caretForwardOutline,
  caretDownOutline,
} from "ionicons/icons";

import { friends, listFamily, listBlockUsers, unblockUser } from "../../services/apiCall";
import { getStrings } from "../../services/lang";
import FriendsList from "../users/FriendsList.vue";

export default {
  props: ["tokenizedUser", "isSelfUser"],
  components: {
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonAvatar,
    IonIcon,
    IonImg,
    IonButton,
    IonText,
    IonSpinner,
    IonLabel,
    //IonInfiniteScroll,
    //IonInfiniteScrollContent,
    FriendsList,
  },
  mounted() {
    this.init();
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
  },
  setup() {
    return {
      getStrings,
    };
  },
  data() {
    const router = useRouter();

    return {
      router,
      caretForwardOutline,
      caretDownOutline,
      myFriendList: [],
      mutualFriends: [],
      friendRequestList: [],
      myFamilyList: [],
      mutualFamily: [],
      familyRequestList: [],
      userFFList: [],
      blockedUsers: [],
      showFriendRequestList: false,
      showFamilyRequestList: false,
      listType: "Friend",
      fetchingFriendList: false,
      friendCount: 0,
      unblockUserClicked: false,
      limit: 6,
      scrollState: {
        scrollDisabled: false,
        scrollSkip: 0,
      },
    };
  },
  methods: {
    init() {
      this.fetchingFriendList = true;
      //friends({ tokenized_user: this.tokenizedUser, limit: this.limit, skip: 0 })
      friends({ tokenized_user: this.tokenizedUser })
        .then((res) => {
          if (res.data.status === 0) {
            this.friendCount = res.data.result.friend_list.length;
            this.myFriendList = res.data.result.friend_list;
            this.mutualFriends = res.data.result.mutual_friends;
            this.friendRequestList = res.data.result.friend_request_list;
            if (this.listType === "Friend") {
              this.userFFList = this.myFriendList;
            } else if (this.listType === "Mutual Friends") {
              this.userFFList = this.mutualFriends;
            }
          }

          this.fetchingFriendList = false;
        })
        .catch((err) => {
          console.log("friend list - err", err);

          this.fetchingFriendList = false;
        });

      listFamily({ tokenized_user: this.tokenizedUser })
      .then((res) => {
        if (res.data.status === 0) {
          this.myFamilyList = res.data.result.family_member_list;
          this.mutualFamily = res.data.result.mutual_family;
          this.familyRequestList = res.data.result.family_request_list;
          if (this.listType === "Family") {
            this.userFFList = this.myFamilyList;
          } else if (this.listType === "Mutual Family") {
            this.userFFList = this.mutualFamily;
          }
        }
      })
      .catch((err) => {
        console.log("friend list - err", err);
      });

      if (this.isSelfUser) {
        listBlockUsers()
        .then((res) => {
          if (res.data.status === 0) {
            this.blockedUsers = res.data.result.block_list;
            if (this.listType === "blocked") {
              this.userFFList = this.blockedUsers;
            }
          }
        })
        .catch((err) => {
          console.log("blocked users list - err", err);
        });
      }
    },
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    loadMoreFriends(ev) {
      const sState = this.scrollState;  
      sState.scrollSkip = sState.scrollSkip + this.limit;
      var cond = {
        tokenized_user: this.tokenizedUser,
        limit: this.limit,
        skip: sState.scrollSkip,
      };

      friends(cond)
        .then(async (res) => {
          if (res.data.result.friend_list.length === 0) {
            sState.scrollDisabled = true;
          } else if (res.data.status === 0 && res.data.result.friend_list.length !== 0) {
            this.myFriendList = this.myFriendList.concat(res.data.result.friend_list);
            if (this.listType === "Friend") {
              this.userFFList = this.myFriendList;
            }
            ev.target.complete();
          } else {
            ev.target.complete();
          }
        })
        .catch((err) => {
          console.log("loadMoreFriends - err2", err);
        });
    },
    showUserList(listType) {
      this.listType = listType;
      if (listType === "Friend") {
        this.userFFList = this.myFriendList;
      } else if (listType === "Mutual Friends") {
        this.userFFList = this.mutualFriends;
      } else if (listType === "Family") {
        this.userFFList = this.myFamilyList;
      } else if (listType === "Mutual Family") {
        this.userFFList = this.mutualFamily;
      } else if (listType === "blocked") {
        this.userFFList = this.blockedUsers;
      }
    },
    showFriendRequestListClickHandler() {
      this.showFriendRequestList = !this.showFriendRequestList;
    },
    showFamilyRequestListClickHandler() {
      this.showFamilyRequestList = !this.showFamilyRequestList;
    },
    async navigateProfile(tokenizedUser) {
      await this.router.push("/user/profile/" + tokenizedUser);
    },
    unblockUser(blockedUser) {
      this.unblockUserClicked = true;
      unblockUser({ user_id: blockedUser.user1_id })
        .then((res) => {
          if (res.data.status === 0) {
            this.blockedUsers = this.blockedUsers.filter((y) => y.user1_id !==  blockedUser.user1_id);
            this.userFFList = this.blockedUsers;
            if (this.blockedUsers.length === 0) {
              this.listType === "Friend";
            }
            this.openToast("You have unblocked " + blockedUser.full_name);
            this.init();
            this.unblockUserClicked = false;
           
          } else {
            console.log("unblockUser - err1");
          }
        })
        .catch((err) => {
          this.unblockUserClicked = false;
          console.log("unblockUser - err2", err);
        });
    },
  },
};
</script>