<template>
  <ion-content>
    <ion-list lines="none">
      <ion-item
        v-for="action in popoverActions"
        :key="action"
        :value="action"
        class="ion-padding-horizontal popover_item_style"
      >
        <ion-content>
          <ion-item class="popover_item_style">
            <ion-icon
              size="small"
              color="dark"
              :icon="optionIcons[action.id]"
            ></ion-icon>
            <ion-button
              color="dark"
              fill="clear"
              size="small"
              style="text-transform: none"
              @click="handleAction(action, userConnections, user)"
            >
              {{ action.name }}
            </ion-button>
          </ion-item>
        </ion-content>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonList,
  IonButton,
  IonIcon,
  toastController,
  popoverController,
} from "@ionic/vue";

import {
  settingsOutline,
  keyOutline,
  personRemoveOutline,
  personAddOutline,
  peopleOutline,
} from "ionicons/icons";

import { useRouter } from "vue-router";

import { defineComponent } from "vue";

import { followUser, unFollow, friendRequest, unFriend, removeFamilyRelation } from "../../services/apiCall";
import { getStrings } from "../../services/lang";
import FamilyRequestOptions from "../users/FamilyRequestOptions.vue";

export default defineComponent({
  name: "ProfileOptionList",
  props: ["popoverActions", "parent", "userConnections", "user"],
  components: {
    IonContent,
    IonItem,
    IonList,
    IonButton,
    IonIcon,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getStrings,
    };
  },
  data() {
    return {
      settingsOutline,
      keyOutline,
      personRemoveOutline,
      personAddOutline,
      peopleOutline,
      optionIcons: {
        'EDIT': settingsOutline,
        'CHANGE_PASSWORD': keyOutline,
        'FOLLOW': personAddOutline,
        'UNFOLLOW': personRemoveOutline,
        'FRIEND': personAddOutline,
        'UNFRIEND': personRemoveOutline,
        'FAMILY': peopleOutline,
        'UNFAMILY': peopleOutline,
      }
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    handleAction(action, userConnections, user) {
      console.log('userConnections:',userConnections);
      if (action.id === "CHANGE_PASSWORD") {
        this.navigateChangePassword();
      } else if (action.id === "EDIT") {
        this.navigateSettings();
      } else if (action.id === "FOLLOW") {
        this.followUserHandler(userConnections, user);
      } else if (action.id === "UNFOLLOW") {
        this.unFollowUserHandler(userConnections, user);
      } else if (action.id === "FRIEND") {
        this.sendFriendRequestHandler(userConnections);
      } else if (action.id === "UNFRIEND") {
        this.unFriendRequestHandler(userConnections, user);
      } else if (action.id === "FAMILY") {
        this.addFamilyRequestClickHandler(userConnections);
      } else if (action.id === "UNFAMILY") {
        this.unFamilyRequestHandler(userConnections, user);
      }
      this.parent.dismiss();
    },
    async navigateChangePassword() {
      await this.router.push("/user/password/change");
    },
    
    async navigateSettings() {
      await this.router.push("/user/profile/all");
    },
    followUserHandler(connections, user) {
      followUser({ follow_to: connections.connection_user_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.i_am_following = true;
            user.followerCount = user.followerCount + 1;
            this.openToast(this.getStrings(this.strings, "YouAreFollowingNow"));
          } else {
            console.log("follow the person - err1");
          }
        })
        .catch((err) => {
          console.log("follow  the person - err2", err);
          this.parent.dismiss();
        });
    },
    unFollowUserHandler(connections, user) {
      unFollow({ unfollow_user_id: connections.connection_user_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.i_am_following = false;
            user.followerCount = user.followerCount - 1;
            this.openToast(this.getStrings(this.strings, "YouHaveUnFollowedTheUser"));
          } else {
            console.log("unFollowHandler - err1");
          }
        })
        .catch((err) => {
          console.log("unFollowHandler - err2", err);
        });
    },
    sendFriendRequestHandler(connections) {
      friendRequest({ request_to: connections.connection_user_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.friend_request_sent = true;
            this.openToast(this.getStrings(this.strings, "FriendRequestSent"));
          }  else {
            console.log("sendFriendRequestHandler - err1");
          }
        })
        .catch((err) => {
          console.log("sendFriendRequestHandler - err2", err);
        });
    },
    unFriendRequestHandler(connections, user) {
      this.submitDisabledUnFriendRequest = true;
      console.log('Connection id:',connections.connection_user_id);
      unFriend({ unfriend_user_id: connections.connection_user_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.are_friends = false;
            user.friendCount = user.friendCount - 1;
          } else {
            console.log("unFriendRequestHandler - err1");
          }
        })
        .catch((err) => {
          console.log("unFriendRequestHandler - err2", err);
        });
    },
    async addFamilyRequestClickHandler(ev) {
      const popover = await popoverController.create({
        component: FamilyRequestOptions,
        event: ev,
        translucent: true,
      });
      popover.componentProps = { userId: this.user.id, parent: popover };
      return popover.present();
    },
    unFamilyRequestHandler(connections, user) {
      this.submitDisabledUnFriendRequest = true;
      removeFamilyRelation({ remove_relation_user_id: connections.connection_user_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.are_family_members = false;
            user.familyCount = user.familyCount - 1;
          } else {
            console.log("unFriendRequestHandler - err1");
          }
        })
        .catch((err) => {
          console.log("unFriendRequestHandler - err2", err);
        });
    },

  },
});
</script>
