<template>
  <ion-button
    fill="clear"
    shape="round"
    color="dark"
    style="text-transform: none"
    @click="addOptionsToActionSheet()"
    class="profile-option-button"
  >
    <span>
      <ion-icon color="dark" slot="icon-only" :icon="ellipsisHorizontal"></ion-icon>
    </span>
  </ion-button>
</template>

<script>
import {
  IonButton, 
  IonIcon,
  toastController,
  popoverController,
  actionSheetController,
  alertController,
} from '@ionic/vue';
import { defineComponent, 
} from 'vue';

import {
  settingsOutline,
  keyOutline,
  logInOutline,
  idCardOutline,
  personRemoveOutline,
  personAddOutline,
  peopleOutline,
  ellipsisHorizontal,
  closeCircleOutline,
  checkmarkCircleOutline,
} from "ionicons/icons";

import { useRouter } from "vue-router";
import { followUser, unFollow, friendRequest, unFriend, removeFamilyRelation, blockUser } from "../../services/apiCall";
import FamilyRequestOptions from "../users/FamilyRequestOptions.vue";
import { getStrings } from "../../services/lang";

export default defineComponent({
  name: "ProfileOptionsSheet",
  props: ["user", "currentUser", "userConnections"],
  components: { 
    IonButton, IonIcon, },
  setup() {
    const router = useRouter();
    const buttons = [];

    return { router, buttons, 
    getStrings }
  },
  data() {
    return {
      ellipsisHorizontal,
      optionIcons: {
        'EDIT': settingsOutline,
        'CHANGE_PASSWORD': keyOutline,
        'CHANGE_PHONE_EMAIL': logInOutline,
        'PROFESSIONAL_PAGE': idCardOutline,
        'FOLLOW': personAddOutline,
        'UNFOLLOW': personRemoveOutline,
        'FRIEND': personAddOutline,
        'UNFRIEND': personRemoveOutline,
        'FAMILY': peopleOutline,
        'UNFAMILY': peopleOutline,
        'BLOCK': closeCircleOutline,
        'UNBLOCK': checkmarkCircleOutline,
      },
      actionSheetOptions: [],
    }
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },  
  },
  mounted() {
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    async addOptionsToActionSheet() {
      this.actionSheetOptions = [
        { id: "CHANGE_PASSWORD", name: this.getStrings(this.strings, "ChangePassword") },
        { id: "EDIT", name: this.getStrings(this.strings, "EditProfile") },
        { id: "CHANGE_PHONE_EMAIL", name: this.getStrings(this.strings, "Change Phone/Email") },
        { id: "PROFESSIONAL_PAGE", name: this.getStrings(this.strings, "Pages") },
      ];

      if (this.currentUser.tokenized_user !== this.user.user_id) {
        this.actionSheetOptions = [];
        if (this.userConnections.i_am_following) {
          this.actionSheetOptions.push({ id: "UNFOLLOW", name: this.getStrings(this.strings, "UnFollow") });
        } else {
          this.actionSheetOptions.push({ id: "FOLLOW", name: this.getStrings(this.strings, "Follow") });
        }
        if (
          !this.userConnections.are_friends &&
          !this.userConnections.friend_request_received &&
          !this.userConnections.friend_request_sent
        ) {
          this.actionSheetOptions.push({ id: "FRIEND", name: this.getStrings(this.strings, "Friend") });
        } else if (this.userConnections.are_friends) {
          this.actionSheetOptions.push({ id: "UNFRIEND", name: this.getStrings(this.strings, "UnFriend") });
        }
        if (
          !this.userConnections.are_family_members &&
          !this.userConnections.family_request_received &&
          !this.userConnections.family_request_sent
        ) {
          this.actionSheetOptions.push({ id: "FAMILY", name: this.getStrings(this.strings, "Family") });
        } else if (this.userConnections.are_family_members) {
          this.actionSheetOptions.push({ id: "UNFAMILY", name: this.getStrings(this.strings, "UnFamily") });
        }
        if (this.userConnections.are_blocked) {
          this.actionSheetOptions.push({ id: "UNBLOCK", name: this.getStrings(this.strings, "Un-block") });
        } else {
          this.actionSheetOptions.push({ id: "BLOCK", name: this.getStrings(this.strings, "Block") });
        }
      }

      this.buttons = [];
      for (var idx = 0; idx < this.actionSheetOptions.length; idx++) {
        this.buttons.push(
          {
            text: this.actionSheetOptions[idx].name,
            icon: this.optionIcons[this.actionSheetOptions[idx].id],
            size: "small",
            handler: this.handleAction.bind(this, this.actionSheetOptions[idx], this.userConnections, this.user),
          }
        );
      }

      const actionSheet = await actionSheetController.create({
        header: 'Profile Options',
        buttons: this.buttons
      });

      await actionSheet.present();
    },
    handleAction(action, userConnections, user) {
      if (action.id === "CHANGE_PASSWORD") {
        this.navigateChangePassword();
      } else if (action.id === "EDIT") {
        this.navigateSettings();
      } else if (action.id === "CHANGE_PHONE_EMAIL") {
        this.navigateChangePhoneEmail();
      } else if (action.id === "PROFESSIONAL_PAGE") {
        this.navigateProfessionalPage();
      } else if (action.id === "FOLLOW") {
        this.followUserHandler(userConnections, user);
      } else if (action.id === "UNFOLLOW") {
        this.unFollowUserHandler(userConnections, user);
      } else if (action.id === "FRIEND") {
        this.sendFriendRequestHandler(userConnections);
      } else if (action.id === "UNFRIEND") {
        this.unFriendRequestHandler(userConnections, user);
      } else if (action.id === "FAMILY") {
        this.addFamilyRequestClickHandler(userConnections);
      } else if (action.id === "UNFAMILY") {
        this.unFamilyRequestHandler(userConnections, user);
      } else if (action.id === "BLOCK") {
        this.blockUserRequestClickHandler(userConnections);
      } else if (action.id === "UNBLOCK") {
        this.unblockUserRequestHandler(userConnections);
      }
      this.actionSheetOptions = [];
    },
    async navigateChangePassword() {
      await this.router.push("/user/password/change");
    },
    
    async navigateSettings() {
      await this.router.push("/user/profile/all");
    },
    async navigateChangePhoneEmail() {
      await this.router.push("/credential/change");
    },
    async navigateProfessionalPage() {
      await this.router.push("/page/list");
    },
    async navigatePostsPage() {
      await this.router.back();
    },
    followUserHandler(connections, user) {
      followUser({ follow_to: connections.connection_user_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.i_am_following = true;
            user.followerCount = user.followerCount + 1;
            this.openToast(this.getStrings(this.strings, "YouAreFollowingNow"));
          } else {
            console.log("follow the person - err1");
          }
        })
        .catch((err) => {
          console.log("follow  the person - err2", err);
          this.parent.dismiss();
        });
    },
    unFollowUserHandler(connections, user) {
      unFollow({ unfollow_user_id: connections.connection_user_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.i_am_following = false;
            user.followerCount = user.followerCount - 1;
            this.openToast(this.getStrings(this.strings, "YouHaveUnFollowedTheUser"));
          } else {
            console.log("unFollowHandler - err1");
          }
        })
        .catch((err) => {
          console.log("unFollowHandler - err2", err);
        });
    },
    sendFriendRequestHandler(connections) {
      friendRequest({ request_to: connections.connection_user_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.friend_request_sent = true;
            this.openToast(this.getStrings(this.strings, "FriendRequestSent"));
          }  else {
            console.log("sendFriendRequestHandler - err1");
          }
        })
        .catch((err) => {
          console.log("sendFriendRequestHandler - err2", err);
        });
    },
    unFriendRequestHandler(connections, user) {
      this.submitDisabledUnFriendRequest = true;
      unFriend({ unfriend_user_id: connections.connection_user_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.are_friends = false;
            user.friendCount = user.friendCount - 1;
          } else {
            console.log("unFriendRequestHandler - err1");
          }
        })
        .catch((err) => {
          console.log("unFriendRequestHandler - err2", err);
        });
    },
    async addFamilyRequestClickHandler(ev) {
      const popover = await popoverController.create({
        component: FamilyRequestOptions,
        event: ev,
        translucent: true,
      });
      popover.componentProps = { userId: this.user.id, parent: popover };
      return popover.present();
    },
    unFamilyRequestHandler(connections, user) {
      this.submitDisabledUnFriendRequest = true;
      removeFamilyRelation({ remove_relation_user_id: connections.connection_user_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.are_family_members = false;
            user.familyCount = user.familyCount - 1;
          } else {
            console.log("unFriendRequestHandler - err1");
          }
        })
        .catch((err) => {
          console.log("unFriendRequestHandler - err2", err);
        });
    },
    async blockUserRequestClickHandler(connections) {
      const anAlert = await alertController.create({
        title: "Confirm Block User",
        message:
          '<span style="text-align: justify; text-justify: inter-word;">Once you block a user you will be you will not be able to interact with the user.<br/><br/><b>Do you really want to block the user?</b></span>',
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              blockUser({ user_id: connections.connection_user_id })
                .then((res) => {
                  if (res.data.status === 0) {            
                    connections.are_blocked = true;
                    this.openToast("You have blocked this user", connections);
                    this.navigatePostsPage();
                  } else {
                    console.log("blockUserRequestClickHandler - err1");
                  }
                })
                .catch((err) => {
                  console.log("blockUserRequestClickHandler - err2", err);
                });
              }
          },
        ],
      });
      await anAlert.present();
    },
    unblockUserRequestClickHandler(connections) {
      console.log('unblockUserRequestClickHandler:', connections);
    },
  },
});
</script>