<template>
  <ion-grid>
    <ion-row>
      <ion-col
        :size="getColSize(false)"
        :offset="getOffsetSize(false)"
        class="ion-no-padding ion-no-margin"
      >
        <ion-card class="ion-no-padding ion-no-margin">
          <ion-row class="center_image_col">
            <ion-col class="center_image_col">
              <ion-avatar
                size="large"
                class="profile-photo-style"
                @click="showZoomedPhoto(user.photo)"
              >
                <ion-img @ionError="flagProfilePhotoNotAvailable(user)" v-if="user.profile_photo_available" :src="user.photo"></ion-img>
                <ion-img v-if="!user.profile_photo_available" src="assets/icon/misc/avatar.svg"></ion-img>
              </ion-avatar>
                <ion-button
                  color="medium"                  
                  shape="round"
                  size="medium"
                  @click="takePhoto(user)"
                  class="profile-photo-button"
                  v-if="currentUser.tokenized_user === user.user_id"
                >
                  <ion-icon :icon="cameraOutline"></ion-icon>
                  <ion-spinner v-if="uploadingPhoto" name="dots"></ion-spinner>
                </ion-button>
                <ion-button
                  color="medium"                  
                  shape="round"
                  size="medium"
                  @click="navigateChat(user.user_id)"
                  class="profile-photo-button"
                  v-if="currentUser.tokenized_user !== user.user_id"
                >
                  <ion-icon :icon="chatbubbleEllipsesOutline"></ion-icon>
                </ion-button>
                <div>
                  <profile-options-sheet :user="user" :currentUser="currentUser" :userConnections="userConnections"></profile-options-sheet>
                </div>
            </ion-col>
          </ion-row>
          <ion-row class="center_align_col bottom-margin-10px">
            <ion-col class="ion-no-padding ion-no-margin">
              <div>
                <b class="line_hight_two">{{ user.full_name }}</b> <br />
              </div>
              <div v-if="currentUser.tokenized_user === user.user_id">
                <span>{{ user.my_bio }}&nbsp;</span>
                <ion-icon
                  @click="updateUserBio(user)"
                  size="medium"
                  style="cursor: pointer;"
                  :color="updateBioVisible ? 'danger' : 'dark'"
                  :icon="updateBioVisible ? closeOutline : createOutline"
                >
                </ion-icon>
              </div>
              <div v-if="currentUser.tokenized_user === user.user_id && updateBioVisible">
                <generic-profile-section
                  :fields="bioField"
                  :section="basicInfoSection"
                >
                </generic-profile-section>
              </div>
              <div v-if="currentUser.tokenized_user !== user.user_id">
                <span v-if="user.my_bio !== '...'">{{ user.my_bio }}&nbsp;</span>
              </div>
              <div>
                <span class="friend_count_text_style line_hight_two">
                  <b>{{ user.friendCount }}</b>&nbsp; {{ getStrings(strings, "Friends") }},
                  <b>{{ user.followerCount }}</b>&nbsp;{{ getStrings(strings, "Followers") }},
                  <b>{{ user.familyCount }}</b>&nbsp;{{ getStrings(strings, "Family") }}
                </span>
              </div>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>
    </ion-row>
    <!-- Friend request, un-friend -->
    <ion-row class="ion-no-padding ion-no-margin" v-if="currentUser.tokenized_user !== user.user_id">
      <ion-col
        :size="getColSize(false)"
        :offset="getOffsetSize(false)"
        class="ion-no-padding ion-no-margin"
      >
        <ion-item
          class="ion-no-padding ion-no-margin"
          no-lines
          lines="none"
          v-if="userConnections.friend_request_received || userConnections.friend_request_sent"
        >
          <ion-toolbar  class="profile_toolbar" mode="md">
            <ion-buttons slot="end" size="small" class="right-margin-15px">
              <ion-button
                slot="end"
                size="small"
                fill="outline"
                shape="round"
                style="text-transform: none"
                v-if="userConnections.friend_request_received"
                @click="acceptFriendRequestHandler(user, userConnections)"
              >
                <ion-icon :icon="personAdd"></ion-icon>
                &nbsp;{{ getStrings(strings, "Accept") }}
              </ion-button>
              <ion-button
                slot="end"
                size="small"
                fill="outline"
                shape="round"
                style="text-transform: none"
                v-if="userConnections.friend_request_received"
                @click="declineFriendRequestHandler(userConnections)"
              >
                <ion-icon :icon="personRemove"></ion-icon>
                &nbsp;{{ getStrings(strings, "Reject") }}
              </ion-button>
              <ion-button
                slot="end"
                size="small"
                fill="outline"
                shape="round"
                style="text-transform: none"
                v-if="userConnections.friend_request_sent"
                @click="deleteFriendRequestHandler(userConnections)"
              >
                &nbsp;{{ getStrings(strings, "DeleteFriendRequest") }}
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-item>
        <ion-item
          class="ion-no-padding ion-no-margin"
          no-lines
          lines="none"
          v-if="userConnections.family_request_received || userConnections.family_request_sent"
        >
          <ion-toolbar  class="profile_toolbar" mode="md">
            <ion-buttons slot="end" size="small" class="right-margin-15px">
              <ion-button
                slot="end"
                size="small"
                fill="outline"
                shape="round"
                style="text-transform: none"
                v-if="userConnections.family_request_received"
                @click="acceptFamilyRequestHandler(user, userConnections)"
              >
                <ion-icon :icon="peopleOutline"></ion-icon>
                &nbsp;{{ getStrings(strings, "Accept") }}
              </ion-button>
              <ion-button
                slot="end"
                size="small"
                fill="outline"
                shape="round"
                style="text-transform: none"
                v-if="userConnections.family_request_received"
                @click="declineFamilyRequestHandler(userConnections)"
              >
                <ion-icon :icon="peopleOutline"></ion-icon>
                &nbsp;{{ getStrings(strings, "Reject") }}
              </ion-button>
              <ion-button
                slot="end"
                size="small"
                fill="outline"
                shape="round"
                style="text-transform: none"  
                v-if="userConnections.family_request_sent"
                @click="deleteFamilyRequestHandler(userConnections)"
              >
                &nbsp;{{ getStrings(strings, "DeleteFamilyRequest") }}
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import {
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonAvatar,
  IonButton,
  IonSpinner,
  IonImg,
  IonIcon,
  IonToolbar,
  IonButtons,
  IonCard,
  toastController,
  modalController,
} from "@ionic/vue";

import { useRouter } from "vue-router";

import {
  cameraOutline,
  chatbubbleEllipsesOutline,
  personAdd,
  personRemove,
  peopleOutline,
  createOutline,
  closeOutline,
} from "ionicons/icons";

import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";
import Jimp from "jimp";
const { Camera } = Plugins;

import ApplyFilterModal from "../media/ApplyFilterModal.vue";

import {
  acceptFriendRequest,
  declineFriendRequest,
  deleteFriendRequest,
  updateUserPhoto,
  fetchUserProfilePhoto,
  acceptFamilyRequest,
  declineFamilyRequest,
  deleteFamilyRequest,
} from "../../services/apiCall";

import { getStrings } from "../../services/lang";
import { profileImageSize,  getColSize, getOffsetSize } from "../../services/utils";
import ProfileOptionsSheet from "./ProfileOptionsSheet.vue"
import PostImageModal from "../posts/PostImageModal.vue";
import GenericProfileSection from "../settings/GenericProfileSection.vue";

export default {
  props: ["user", "currentUser", "userConnections"],
  components: {
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonAvatar,
    IonButton,
    IonSpinner,
    IonImg,
    IonIcon,
    IonToolbar,
    IonButtons,
    IonCard,
    ProfileOptionsSheet,
    GenericProfileSection,
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
  },
  setup() {
    return {
      getStrings,
    };
  },
  data() {
    const router = useRouter();

    return {
      getColSize,
      getOffsetSize,
      router,
      cameraOutline,
      chatbubbleEllipsesOutline,
      personAdd,
      personRemove,
      peopleOutline,
      createOutline,
      closeOutline,
      uploadingPhoto: false,
      photoWidth: 150,
      updateBioVisible: false,
      bioField: [],
      basicInfoSection: [],
      currentUserProfile: {
        photo: "",
        photo_thumbnail: "",
      },
    };
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    acceptFriendRequestHandler(user, connections) {
      acceptFriendRequest({ friend_request_id: connections.friend_request_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.are_friends = true;
            connections.friend_request_received = false;
            connections.friend_request_sent = false;
            user.friendCount = user.friendCount + 1;
            this.openToast(this.getStrings(this.strings, "FriendRequestAccepted"));
          } else {
            console.log("acceptFriendRequestHandler - err1");
          }
        })
        .catch((err) => {
          console.log("acceptFriendRequestHandler - err2", err);
        });
    },
    declineFriendRequestHandler(connections) {
      declineFriendRequest({ friend_request_id: connections.friend_request_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.friend_request_received = false;
            connections.friend_request_sent = false;
            this.openToast(this.getStrings(this.strings, "FriendRequestRejected"));
          } else {
            console.log("declineFriendRequestHandler - err1");
          }
        })
        .catch((err) => {
          console.log("declineFriendRequestHandler - err2", err);
        });
    },
    deleteFriendRequestHandler(connections) {
      deleteFriendRequest({ friend_request_id: connections.friend_request_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.friend_request_received = false;
            connections.friend_request_sent = false;
            this.openToast(this.getStrings(this.strings, "FriendRequestDeleted"));
          } else {
            console.log("deleteFriendRequestHandler - err1");
          }
        })
        .catch((err) => {
          console.log("deleteFriendRequestHandler - err2", err);
        });
    },
    fetchProfilePhoto() {
      fetchUserProfilePhoto()
        .then((res) => {
          if (res.data.status === 0) {
            this.currentUserProfile.photo = res.data.result.profile_photo.photo;
            this.currentUserProfile.photo_thumbnail =
              res.data.result.profile_photo.photo_thumbnail;
          } else {
            console.log("fetchUserProfilePhoto - err", res);
          }
        })
        .catch((err) => {
          console.log("fetchUserProfilePhoto - err", err);
        });
    },
    acceptFamilyRequestHandler(user, connections) {
      acceptFamilyRequest({ family_request_id: connections.family_request_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.are_family_members = true;
            connections.family_request_received = false;
            connections.family_request_sent = false;
            user.familyCount = user.familyCount + 1;
            this.openToast(this.getStrings(this.strings, "FamilyRequestAccepted"));
          } else {
            console.log("acceptFamilyRequestHandler - err1");
          }
        })
        .catch((err) => {
          console.log("acceptFamilyRequestHandler - err2", err);
        });
    },
    declineFamilyRequestHandler(connections) {
      declineFamilyRequest({ family_request_id: connections.family_request_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.family_request_received = false;
            connections.family_request_sent = false;
            this.openToast(this.getStrings(this.strings, "FamilyRequestRejected"));
          } else {
            console.log("declineFamilyRequestHandler - err1");
          }
        })
        .catch((err) => {
          console.log("declineFamilyRequestHandler - err2", err);
        });
    },
    deleteFamilyRequestHandler(connections) {
      deleteFamilyRequest({ family_request_id: connections.family_request_id })
        .then((res) => {
          if (res.data.status === 0) {
            connections.family_request_received = false;
            connections.family_request_sent = false;
            this.openToast(this.getStrings(this.strings, "FamilyRequestDeleted"));
          } else {
            console.log("deleteFamilyRequestHandler - err1");
          }
        })
        .catch((err) => {
          console.log("deleteFamilyRequestHandler - err2", err);
        });
    },
    async takePhoto(user) {
      try {
        this.fetchProfilePhoto();
        const imgSize = profileImageSize();

        // take the photo
        const photo = await Camera.getPhoto({
          resultType: CameraResultType.Uri,
          source: CameraSource.Prompt,
          width: imgSize,
          preserveAspectRatio: true,
          correctOrientation: true,
        });

        // rescale to 300px
        let imageObj = await Jimp.read(photo.webPath);
        let ratio = imgSize / imageObj.getWidth();
        let scaledImgObj = await imageObj.scale(ratio);

        let imgB64 = await scaledImgObj.getBase64Async("image/png");
        this.currentUserProfile.photo = imgB64;

        this.uploadingPhoto = true;

        // filter call back
        var filterCallback = (filteredImg) => {
          this.currentUserProfile.photo = filteredImg;
          this.currentUserProfile.photo_thumbnail = filteredImg;

          updateUserPhoto({ photo: this.currentUserProfile.photo })
            .then((res) => {
              if (res.data.status === 0) {
                user.photo = this.currentUserProfile.photo;
                this.$store.dispatch(
                  "setcurrentprofilephoto",
                  this.currentUserProfile
                );
              } else {
              console.log("updateUserPhoto - err2", res);
              }
              this.uploadingPhoto = false;
            })
            .catch((err) => {
              console.log("updateUserPhoto - err", err);
              this.uploadingPhoto = false;
            });
        };

        // apply filter - dialog
        const modal = await modalController.create({
          component: ApplyFilterModal,
          mode: "md",
        });
        modal.componentProps = {
          imgSrc: this.currentUserProfile.photo,
          parent: modal,
          filter_callback: filterCallback,
        };

        return modal.present();
      } catch (e) {
        this.uploadingPhoto = false;
        console.log("takePhoto - err", e);
      }
    },
    updateUserBio(user) {
      if (this.updateBioVisible === false) {
        this.basicInfoSection = user.profile.filter((y) => y.section_name === 'BASIC_INFO');
        if (this.basicInfoSection.length > 0) {
          this.basicInfoSection = this.basicInfoSection[0];
          this.bioField = this.basicInfoSection.fields.filter((y) => y.field_name === 'bio');
          if (this.bioField.length > 0) {
            this.updateBioVisible = true;
          }
        }
      } else {
        this.updateBioVisible = false;
      }
    },
    async navigateChat(tokenizedUser) {
      await this.router.replace("/chat/" + tokenizedUser);
    },
    async showZoomedPhoto(profilePhoto) {
      if (!profilePhoto) return;
      try {
        var imgSrc = profilePhoto;
        const modal = await modalController.create({
          component: PostImageModal,
          mode: "md",
        });
        modal.componentProps = {
          title: 'Profile Photo',
          imgSrc: imgSrc,
          parent: modal,
        };
        return modal.present();
      } catch (err) {
        console.log("profile photo err", err);
      }
    },
    flagProfilePhotoNotAvailable(user) {
      user.profile_photo_available = false;
    }
  },
};
</script>